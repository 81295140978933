import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Skeleton,
  Switch,
  Typography,
} from "antd";
import React, { useCallback, useState } from "react";
import { DynamicVariableSelect } from "./DynamicVariableSelect";
import { Editable, RenderElementProps, Slate, withReact } from "slate-react";
import {
  BaseText,
  createEditor,
  Descendant,
  Editor,
  Transforms,
  Text,
  Element,
} from "slate";
import {
  getFormatDescription,
  getFriendlyVariableName,
  parseVariable,
  WorkflowVariableFormatType,
  WorkflowVariableType,
} from "../../../util/helpers";
import { withHistory } from "slate-history";
import { blue, red } from "@ant-design/colors";
import { CustomElement, VariableElement } from "../../../../..";
import { CRMFieldType } from "../../../../../../util/enums";
import { CodeOutlined } from "@ant-design/icons";
import { useWorkflowVariableDescription } from "../../../util/useWorkflowVariableDescription";
import { DateTimeDynamicVariableInput } from "./DateTimeDynamicVariableInput";
import { useUpstreamWorkflowStates } from "../../../util/useUpstreamWorkflowStates";
import { useParams } from "react-router-dom";

export function VariableElement(props: RenderElementProps) {
  const { variable } = props.element as VariableElement;
  const { variableType, attributes } = parseVariable(variable);
  const formatDescription = getFormatDescription(variable);

  const { workflowUuid, workflowStateUuid } = useParams();

  const { description: variableDescription, isLoading: descriptionLoading } =
    useWorkflowVariableDescription(variable);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [upstreamWorkflowStatesLoading, upstreamWorkflowStates] =
    useUpstreamWorkflowStates({
      workflowUuid,
      workflowStateUuid: workflowStateUuid,
      includeCurrentWorkflowState: window.location.pathname.includes("/new"),
    });

  const isLoading = descriptionLoading || upstreamWorkflowStatesLoading;

  let invalidVariableMessage;
  if (
    variableType === WorkflowVariableType.Action &&
    !upstreamWorkflowStates.map(({ uuid }) => uuid).includes(attributes[0])
  ) {
    invalidVariableMessage =
      "Variable not found. Please avoid copying and pasting variables.";
  } else if (!variableType) {
    invalidVariableMessage = "Invalid variable type.";
  }

  return (
    <Popover
      content={
        <>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Descriptions
              colon={false}
              size="small"
              style={{ maxWidth: 400 }}
              layout="vertical"
              column={1}
            >
              {invalidVariableMessage ? (
                <Typography.Text>{invalidVariableMessage}</Typography.Text>
              ) : (
                <>
                  {variableDescription && (
                    <Descriptions.Item label="Variable Description">
                      {variableDescription}
                    </Descriptions.Item>
                  )}

                  {formatDescription && (
                    <Descriptions.Item label="Format">
                      {formatDescription}
                    </Descriptions.Item>
                  )}
                </>
              )}
            </Descriptions>
          )}
        </>
      }
    >
      <span
        {...props.attributes}
        contentEditable={false}
        style={{
          cursor: "pointer",
          backgroundColor: invalidVariableMessage ? red[0] : blue[0],
          padding: 3,
          margin: "0px 1px",
          borderRadius: 2,
        }}
      >
        {props.children}
      </span>
    </Popover>
  );
}

interface DynamicVariableTextareaProps {
  value: string;
  fieldType?: CRMFieldType;
  showBorder?: boolean;
  onChange: (value: string) => void;
}

export function DynamicVariableTextarea({
  value,
  fieldType,
  showBorder = true,
  onChange,
}: DynamicVariableTextareaProps) {
  const [isDynamicVariableModalOpen, setIsDynamicVariableModalOpen] =
    useState<boolean>(false);

  const [selectedVariable, setSelectedVariable] = useState<string>(null);
  const [selectedVariableType, setSelectedVariableType] =
    useState<WorkflowVariableType>(WorkflowVariableType.Action);

  const workflowVariableFieldTypeResponse =
    useWorkflowVariableDescription(selectedVariable);

  const [editor] = useState(() =>
    withInlines(withHistory(withReact(createEditor())))
  );

  const renderElement = useCallback((props: RenderElementProps) => {
    switch (props.element.type) {
      case "variable":
        return <VariableElement {...props} />;
      default:
        return (
          <Typography.Paragraph {...props.attributes}>
            {props.children}
          </Typography.Paragraph>
        );
    }
  }, []);

  return (
    <>
      <Modal
        destroyOnClose
        title="Insert Variable"
        open={isDynamicVariableModalOpen}
        width="50rem"
        okText="Insert Variable"
        onCancel={() => setIsDynamicVariableModalOpen(false)}
        onOk={() => {
          Editor.insertNode(editor, {
            type: "variable",
            variable: selectedVariable,
            children: [
              { text: `{{${getFriendlyVariableName(selectedVariable)}}}` },
            ],
          });

          Transforms.select(editor, {
            anchor: Editor.end(editor, []),
            focus: Editor.end(editor, []),
          });

          setSelectedVariable(null);
          setIsDynamicVariableModalOpen(false);
        }}
        okButtonProps={{
          disabled:
            workflowVariableFieldTypeResponse?.isLoading || !selectedVariable,
          loading: workflowVariableFieldTypeResponse?.isLoading,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Variable Type">
            <Radio.Group
              value={selectedVariableType}
              onChange={(e) => {
                setSelectedVariable(null);
                setSelectedVariableType(e.target.value);
              }}
              optionType="button"
              options={[
                {
                  label: "Response Attribute",
                  value: WorkflowVariableType.Action,
                },
                {
                  label: "Relative Date/Time",
                  value: WorkflowVariableType.Datetime,
                },
              ]}
            />
          </Form.Item>

          {selectedVariableType === WorkflowVariableType.Action && (
            <Form.Item label="Variable Name">
              <DynamicVariableSelect
                autoFocus
                value={selectedVariable}
                fieldType={fieldType}
                onChange={setSelectedVariable}
              />
            </Form.Item>
          )}

          {selectedVariableType === WorkflowVariableType.Datetime && (
            <Form.Item label="Relative Date & Time">
              <DateTimeDynamicVariableInput
                value={selectedVariable}
                onChange={setSelectedVariable}
              />
            </Form.Item>
          )}

          {[
            CRMFieldType.Date,
            CRMFieldType.DateTime,
            CRMFieldType.Time,
          ].includes(workflowVariableFieldTypeResponse?.type) && (
            <Form.Item label="Format (Optional)">
              <Select
                allowClear
                placeholder="Select format type (optional)"
                options={[
                  {
                    label: "Format Date",
                    value: WorkflowVariableFormatType.Date,
                  },
                  {
                    label: "Format Date & Time",
                    value: WorkflowVariableFormatType.Datetime,
                  },
                ]}
                onChange={(value) => {
                  const { variableType, attributes } =
                    parseVariable(selectedVariable);

                  setSelectedVariable(
                    `{{${value} ${variableType}.${attributes.join(".")}}}`
                  );
                }}
              />
            </Form.Item>
          )}

          {[
            CRMFieldType.Picklist,
            CRMFieldType.Multipicklist,
            CRMFieldType.Reference,
          ].includes(workflowVariableFieldTypeResponse?.type) && (
            <Form.Item label="Format to use picklist label (Optional)">
              <Switch
                onChange={(checked) => {
                  const { variableType, attributes } =
                    parseVariable(selectedVariable);

                  const rootVariable = `${variableType}.${attributes.join(
                    "."
                  )}`;

                  setSelectedVariable(
                    checked
                      ? `{{format_picklist ${rootVariable}}}`
                      : `{{${rootVariable}}}`
                  );
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>

      <Row
        style={
          showBorder
            ? {
                padding: 10,
                border: `1px solid #d9d9d9`,
                borderRadius: 5,
              }
            : null
        }
      >
        <Col>
          <Button
            size="small"
            icon={<CodeOutlined />}
            onClick={() => setIsDynamicVariableModalOpen(true)}
          >
            Insert Variable
          </Button>
        </Col>

        <Col span={24}>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
        </Col>

        <Col span={24}>
          <Slate
            editor={editor}
            initialValue={deserialize(value)}
            onValueChange={(value) => onChange(serialize(value))}
          >
            <Editable
              placeholder="Enter text here..."
              style={{ outline: 0 }}
              renderElement={renderElement}
              onKeyUp={() => {
                const { anchor } = editor.selection;
                const { path, offset } = anchor;

                const [node] = Editor.node(editor, path);
                const text = (node as { text: string }).text;

                const startIndex = Math.max(0, offset - 2);

                if (text.slice(startIndex, offset) == "{{") {
                  Transforms.delete(editor, {
                    at: {
                      anchor: { path: anchor.path, offset: anchor.offset - 2 },
                      focus: anchor,
                    },
                  });

                  setIsDynamicVariableModalOpen(true);
                }
              }}
            />
          </Slate>
        </Col>
      </Row>
    </>
  );
}

const withInlines = (editor) => {
  const { isInline, isElementReadOnly } = editor;

  editor.isInline = (element) =>
    ["variable"].includes(element.type) || isInline(element);

  editor.isElementReadOnly = (element) =>
    element.type === "variable" || isElementReadOnly(element);

  return editor;
};

const deserialize = (string: string): Descendant[] => {
  if (!string) return [{ type: "paragraph", children: [{ text: "" }] }];

  return string.split("\n").map((line) => {
    const lineElements = line.split(/(\{\{.*?\}\})/g);

    return {
      type: "paragraph",
      children: lineElements.map((line) => {
        const isVariable = line.match(/(\{\{.*?\}\})/g);

        if (isVariable) {
          const friendlyVariableName = getFriendlyVariableName(line);

          return {
            type: "variable",
            variable: line,
            children: [
              {
                text: friendlyVariableName.length
                  ? `{{${friendlyVariableName}}}`
                  : "Invalid variable",
              },
            ],
          };
        } else {
          return { text: line };
        }
      }),
    };
  });
};

const serialize = (value: Descendant[]) => {
  if (!value) return "";

  return value
    .map((node) => {
      if (Element.isElement(node)) {
        return node.children
          .map((node) => {
            if (Text.isText(node)) return (node as BaseText).text;

            const customElementNode = node as CustomElement;
            if (customElementNode.type === "variable") {
              return customElementNode.variable;
            }

            return "";
          })
          .join("");
      } else {
        return { text: "" };
      }
    })
    .join("\n");
};
