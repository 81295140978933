import React, { useEffect, useMemo, useState } from "react";
import {
  AssociatedCRMRecord,
  AutoGeneratedField,
  CRMField,
  CRMRecord,
  FieldConfiguration,
  FieldConfigurationGroup,
  FieldConfigurationGroupLayout,
  Meeting,
  SyncedCRMObject,
} from "../../util/types";
import {
  Affix,
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { AutoGeneratedFieldCard } from "./auto_generated_field/AutoGeneratedFieldCard";
import { SwyftAutoGeneratedFieldCard } from "./auto_generated_field/SwyftAutoGeneratedFieldCard";
import { MeetingFormFieldItem } from "./MeetingFormFieldItem";
import {
  useAssociatedCRMRecords,
  useFindCRMRecordById,
} from "../../util/data_hooks";
import { CRMRecordTag } from "../shared/CRMRecordTag";
import { parseCRMValue } from "../../util/parseCRMValue";
import { AssociatedCRMRecordModal } from "./AssociatedCRMRecordModal";
import { SearchOutlined } from "@ant-design/icons";
import { SwyftIcon } from "../shared/SwyftIcon";

export const getVisibleFieldConfigurations = (
  layout: FieldConfigurationGroupLayout,
  fieldConfigurationGroup: FieldConfigurationGroup
): FieldConfiguration[] => {
  return layout.field_configuration_layouts.map((field_layout) => {
    return fieldConfigurationGroup.field_configurations.find(
      ({ uuid }) => uuid === field_layout.field_configuration.uuid
    );
  });
};

const getMeetingFormField = ({
  form,
  meeting,
  crmRecord,
  syncedCRMObject,
  fieldConfigurationGroup,
  fieldConfiguration,
  autoGeneratedFields,
  setAssociatedCRMRecordModalOpen,
}: {
  form: FormInstance;
  meeting: Meeting;
  crmRecord: CRMRecord;
  syncedCRMObject: SyncedCRMObject;
  fieldConfigurationGroup: FieldConfigurationGroup;
  fieldConfiguration: FieldConfiguration;
  autoGeneratedFields: AutoGeneratedField[];
  setAssociatedCRMRecordModalOpen: (open: boolean) => void;
}) => {
  if (!meeting || !fieldConfiguration) return null;

  const crmFields = crmRecord?.crm_record_fields || [];
  const crmField = findCrmField(crmFields, fieldConfiguration);

  const autoGeneratedField = findAutoGeneratedField(
    autoGeneratedFields,
    fieldConfiguration
  );

  if (
    fieldConfiguration.hide_if_screened &&
    (!autoGeneratedField?.content || autoGeneratedField?.content?.length === 0)
  ) {
    return null;
  }

  if (
    fieldConfiguration.crm_managed &&
    fieldConfiguration.automated &&
    autoGeneratedField &&
    crmField
  ) {
    return (
      <AutoGeneratedFieldCard
        name={[fieldConfigurationGroup.uuid, crmField.crm_field_name]}
        meeting={meeting}
        autoGeneratedField={autoGeneratedField}
        crmRecord={crmRecord}
        crmField={crmField}
        onSelectCRMRecordClick={() => setAssociatedCRMRecordModalOpen(true)}
      />
    );
  } else if (fieldConfiguration.crm_managed && crmField) {
    return (
      <MeetingFormFieldItem
        name={[fieldConfigurationGroup.uuid, crmField.crm_field_name]}
        label={crmField.crm_field_label}
        fieldType={crmField.crm_field_type}
        required={crmField.crm_field_required && !!crmRecord.crm_record_id}
        length={crmField.crm_field_length}
        picklistItems={crmField.crm_field_picklist_values}
        tooltipText={
          crmRecord.crm_record_id
            ? "Please manually enter data for this CRM field"
            : `Please select the associated ${syncedCRMObject.crm_object_label} for this meeting`
        }
        disabled={!crmRecord.crm_record_id}
      />
    );
  } else if (
    !fieldConfiguration.crm_managed &&
    fieldConfiguration.automated &&
    autoGeneratedField
  ) {
    return (
      <SwyftAutoGeneratedFieldCard
        form={form}
        meeting={meeting}
        fieldConfigurationGroup={fieldConfigurationGroup}
        fieldConfiguration={fieldConfiguration}
        autoGeneratedField={autoGeneratedField}
      />
    );
  }
};

const findAutoGeneratedField = (
  autoGeneratedFields: AutoGeneratedField[],
  fieldConfiguration: FieldConfiguration
): AutoGeneratedField => {
  if (!fieldConfiguration) return null;

  return autoGeneratedFields.find(
    (autoGeneratedField) =>
      autoGeneratedField.field_configuration.uuid === fieldConfiguration.uuid
  );
};

export const findAssociatedCRMRecord = (
  associatedCRMRecords: AssociatedCRMRecord[],
  syncedCRMObject: SyncedCRMObject
): AssociatedCRMRecord => {
  if (!syncedCRMObject) return null;

  return associatedCRMRecords.find(
    ({ synced_crm_object }) =>
      synced_crm_object.crm_object_type === syncedCRMObject.crm_object_type
  );
};

const findCrmField = (
  crmFields: CRMField[],
  fieldConfiguration: FieldConfiguration
) => {
  return crmFields.find(
    (crmField) => crmField.crm_field_name === fieldConfiguration.name
  );
};

interface MeetingFormFieldsProps {
  form: FormInstance;
  formSubmittedAt: Date;
  meeting: Meeting;
  mutateMeeting: () => void;
  autoGeneratedFields: AutoGeneratedField[];
  fieldConfigurationGroup: FieldConfigurationGroup;
  layout: FieldConfigurationGroupLayout;
}

export function MeetingFormFields({
  form,
  formSubmittedAt,
  meeting,
  mutateMeeting,
  autoGeneratedFields,
  fieldConfigurationGroup,
  layout,
}: MeetingFormFieldsProps) {
  const [associatedCRMRecordModalOpen, setAssociatedCRMRecordModalOpen] =
    useState<boolean>(false);

  const {
    associatedCRMRecords,
    isLoading: isAssociatedCRMRecordsLoading,
    mutate: mutateAssociatedCRMRecords,
  } = useAssociatedCRMRecords(meeting?.uuid);

  const {
    associatedCRMRecords: predictedAssociatedCRMRecords,
    isLoading: isPredictedAssociatedCRMRecordsLoading,
    mutate: mutatePredictedAssociatedCRMRecords,
  } = useAssociatedCRMRecords(meeting?.uuid, true);

  const associatedCRMRecord = findAssociatedCRMRecord(
    associatedCRMRecords,
    fieldConfigurationGroup.synced_crm_object
  );

  const {
    record,
    isLoading: isCRMRecordLoading,
    mutate: mutateCRMRecord,
  } = useFindCRMRecordById(
    fieldConfigurationGroup.synced_crm_object?.crm_object_type,
    fieldConfigurationGroup.synced_crm_object?.searchable_crm_field_name,
    associatedCRMRecord?.crm_record_id
  );

  const isLoading =
    isAssociatedCRMRecordsLoading ||
    isPredictedAssociatedCRMRecordsLoading ||
    isCRMRecordLoading;

  const fieldConfigurations =
    fieldConfigurationGroup?.field_configurations || [];

  const suggestedAssociatedCRMRecord = predictedAssociatedCRMRecords.find(
    ({ synced_crm_object }) =>
      synced_crm_object?.uuid ===
      fieldConfigurationGroup.synced_crm_object?.uuid
  );

  useEffect(() => {
    if (isLoading || !record) return;

    const values = record?.crm_record_fields?.reduce((obj, crmField) => {
      const { crm_field_name, crm_field_value, crm_field_type } = crmField;

      const autoGeneratedField = findAutoGeneratedField(
        autoGeneratedFields,
        fieldConfigurations.find(({ name }) => name === crm_field_name)
      );

      return {
        ...obj,
        [crm_field_name]: autoGeneratedField?.accepted
          ? autoGeneratedField.content
          : parseCRMValue(crm_field_type, crm_field_value),
      };
    }, {});

    form.setFieldsValue({
      [fieldConfigurationGroup.uuid]: values,
      [record.crm_record_id]: record,
    });
  }, [isLoading, autoGeneratedFields, record]);

  useEffect(() => {
    if (!formSubmittedAt) return;

    mutateCRMRecord();
    mutateAssociatedCRMRecords();
  }, [formSubmittedAt]);

  // Map of field to autoGeneratedField, where the key is the field uuid
  const autoGeneratedFieldMap = useMemo(() => {
    return fieldConfigurations.reduce((acc, field) => {
      const autogeneratedField = findAutoGeneratedField(
        autoGeneratedFields,
        field
      );
      if (autogeneratedField) {
        acc[field.uuid] = autogeneratedField;
      }
      return acc;
    }, {});
  }, [autoGeneratedFields, fieldConfigurations]);

  // Map of whether a field can shrink, where the key is the field uuid.
  // Only non-generated fields (fields that are null in the autoGeneratedFieldMap) can be shrunk.
  // Only pairs of consecutive fields can be shrunk. If there are 3 consecutive fields, the first 2 can be shrunk.
  // If there are 4 consecutive fields, the first 2 and the last 2 can be shrunk.
  const canShrinkMap = useMemo(() => {
    return fieldConfigurations.reduce((acc, field, index) => {
      if (autoGeneratedFieldMap[field.uuid] || index === 0) {
        acc[field.uuid] = false;
      } else {
        // If the previous field is a generated field, set the current field to false.
        if (autoGeneratedFieldMap[fieldConfigurations[index - 1].uuid]) {
          acc[field.uuid] = false;
        } else {
          // If the previous field is true, set the current field to false.
          // This indicates that a previous pair was already shrunk.
          if (acc[fieldConfigurations[index - 1].uuid]) {
            acc[field.uuid] = false;
          } else {
            // Otherwise, set the pair to true
            acc[field.uuid] = true;
            acc[fieldConfigurations[index - 1].uuid] = true;
          }
        }
      }
      return acc;
    }, {});
  }, [fieldConfigurations, autoGeneratedFields]);

  const constructedFields = getVisibleFieldConfigurations(
    layout,
    fieldConfigurationGroup
  )
    .map((fieldConfiguration) => {
      if (isLoading) return null;

      const meetingFormField = getMeetingFormField({
        form,
        meeting,
        syncedCRMObject: fieldConfigurationGroup.synced_crm_object,
        fieldConfigurationGroup,
        fieldConfiguration,
        crmRecord: record,
        autoGeneratedFields,
        setAssociatedCRMRecordModalOpen,
      });

      if (meetingFormField) {
        return (
          <Col
            key={fieldConfiguration.uuid}
            xs={canShrinkMap[fieldConfiguration.uuid] ? 12 : 24}
          >
            {meetingFormField}
          </Col>
        );
      } else {
        return null;
      }
    })
    .filter(Boolean);

  return (
    <div>
      <AssociatedCRMRecordModal
        open={associatedCRMRecordModalOpen}
        meeting={meeting}
        autoGeneratedFields={autoGeneratedFields.filter(
          ({ field_configuration_uuid }) =>
            fieldConfigurationGroup.field_configurations
              .map(({ uuid }) => uuid)
              .includes(field_configuration_uuid)
        )}
        syncedCRMObjects={[fieldConfigurationGroup.synced_crm_object]}
        associatedCRMRecords={[associatedCRMRecord].filter(Boolean)}
        predictedAssociatedCRMRecords={[suggestedAssociatedCRMRecord].filter(
          Boolean
        )}
        onSave={() => {
          mutateAssociatedCRMRecords();
          mutatePredictedAssociatedCRMRecords();
          mutateMeeting();
        }}
        onClose={() => setAssociatedCRMRecordModalOpen(false)}
      />

      <Form.Item hidden name={record?.crm_record_id} />

      <Affix offsetTop={15} style={{ marginBottom: 15, width: "40%" }}>
        <Card
          size="small"
          style={{
            boxShadow: "5px 3px 3px rgba(140, 140, 140, 0.25)",
            border: "none",
            overflow: "hidden",
          }}
        >
          <Row
            align="middle"
            gutter={[14, 12]}
            justify="space-between"
            wrap={false}
          >
            <Col span={14}>
              <Typography.Title
                level={4}
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                ellipsis
              >
                {fieldConfigurationGroup.label}
              </Typography.Title>
            </Col>

            <Col
              span={10}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {fieldConfigurationGroup.synced_crm_object ? (
                <>
                  {record?.crm_record_id && associatedCRMRecord ? (
                    <Space>
                      <Tooltip
                        title={`Select a different ${fieldConfigurationGroup.synced_crm_object.crm_object_label}`}
                      >
                        <Button
                          size="small"
                          shape="circle"
                          icon={<SearchOutlined />}
                          onClick={() => setAssociatedCRMRecordModalOpen(true)}
                        />
                      </Tooltip>

                      <CRMRecordTag
                        showBackground
                        crm={record.crm}
                        name={record.crm_record_name}
                        url={record.crm_record_url}
                      />
                    </Space>
                  ) : (
                    <Button
                      disabled={isLoading}
                      type="dashed"
                      size="small"
                      onClick={() => setAssociatedCRMRecordModalOpen(true)}
                    >
                      Select{" "}
                      {
                        fieldConfigurationGroup.synced_crm_object
                          .crm_object_label
                      }
                      {suggestedAssociatedCRMRecord && !isLoading
                        ? " (1 Suggestion)"
                        : ""}
                    </Button>
                  )}
                </>
              ) : (
                <SwyftIcon inverted width={25} />
              )}
            </Col>
          </Row>
        </Card>
      </Affix>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[12, 24]}>
          {constructedFields.map((constructedField) => constructedField)}
        </Row>
      )}
    </div>
  );
}
