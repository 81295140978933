import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Affix,
  Alert,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import {
  useAssociatedCRMRecords,
  useAutoGeneratedFields,
  useFieldConfigurationGroupLayouts,
  useMeeting,
  useMyIntegrations,
  useTeamFieldConfigurationGroups,
  useUser,
} from "../../util/data_hooks";
import {
  fetchRegeneratePredictions,
  triggerWorkflow,
  fetchUpdateCRMRecord,
  fetchUpdateMeeting,
} from "../../util/api";
import { meetingsPath } from "../../util/path_builder";
import { CRMField, CRMRecord } from "../../util/types";
import { DateTag } from "../shared/DateTag";
import { AssociatedCRMRecordModal } from "./AssociatedCRMRecordModal";
import { UserRole, WorkflowTrigger } from "../../util/enums";
import { getFriendlyIntegrationLabel } from "../../helpers/label_maps";
import { CRMIcon } from "../shared/CRMIcon";
import { AppContext } from "../App";
import { MeetingProviderAlert } from "./MeetingProviderAlert";
import {
  MeetingFormFields,
  findAssociatedCRMRecord,
  getVisibleFieldConfigurations,
} from "./MeetingFormFields";
import { usePageTitle } from "../hooks/usePageTitle";
import { MeetingStatus } from "../shared/MeetingStatus";

export enum MeetingFormFieldName {
  OpportunityName = "opportunity_name",
  AccountId = "account_id",
  Summary = "summary",
}

export function MeetingForm() {
  const navigate = useNavigate();
  const context = useContext(AppContext);

  const [form] = useForm();
  const { meetingId } = useParams();

  const [associatedCRMRecordModalOpen, setAssociatedCRMRecordModalOpen] =
    useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formSubmittedAt, setFormSubmittedAt] = useState<Date>(null);

  const { user, isLoading: isUserLoading } = useUser();
  const { meetingIntegrations, isLoading: isIntegrationsLoading } =
    useMyIntegrations();

  const {
    meeting,
    mutate: mutateMeeting,
    isLoading: isMeetingLoading,
  } = useMeeting(meetingId);

  usePageTitle(meeting?.topic);

  const {
    autoGeneratedFields,
    isLoading: isAutoGeneratedFieldsLoading,
    mutate: mutateAutoGeneratedFields,
  } = useAutoGeneratedFields(meetingId);

  const {
    associatedCRMRecords,
    isLoading: isAssociatedCRMRecordsLoading,
    mutate: mutateAssociatedCRMRecords,
  } = useAssociatedCRMRecords(meetingId);

  const {
    associatedCRMRecords: predictedAssociatedCRMRecords,
    isLoading: isPredictedAssociatedCRMRecordsLoading,
    mutate: mutatePredictedAssociatedCRMRecords,
  } = useAssociatedCRMRecords(meetingId, true);

  const {
    fieldConfigurationGroups,
    isLoading: isFieldConfigurationGroupsLoading,
  } = useTeamFieldConfigurationGroups(user?.team?.uuid);

  const {
    fieldConfigurationGroupLayouts,
    isLoading: fieldConfigurationGroupLayoutsLoading,
  } = useFieldConfigurationGroupLayouts(user?.team);

  const meetingIntegration = meetingIntegrations.find(
    ({ name }) => name === meeting?.provider_name
  );

  const regenerationAllowed =
    user?.roles?.includes(UserRole.SuperAdmin) ||
    ["development", "staging"].includes(context.environment);

  const isGeneratingPredictions =
    meeting?.predictions_requested_at && !meeting?.predictions_completed_at;

  const isAppending =
    meeting?.append_requested_at && !meeting?.append_completed_at;

  const isLoading =
    isUserLoading ||
    isMeetingLoading ||
    isAutoGeneratedFieldsLoading ||
    isAssociatedCRMRecordsLoading ||
    isPredictedAssociatedCRMRecordsLoading ||
    isFieldConfigurationGroupsLoading ||
    isGeneratingPredictions ||
    isAppending ||
    isIntegrationsLoading ||
    fieldConfigurationGroupLayoutsLoading;

  useEffect(() => {
    if (isLoading || isGeneratingPredictions || isAppending) return;

    mutateAutoGeneratedFields();
    mutateAssociatedCRMRecords();
    mutatePredictedAssociatedCRMRecords();

    if (meeting && meeting.predictions_completed_at) {
      fetchUpdateMeeting(meeting.uuid, { reviewed_at: new Date() });
    }
  }, [isLoading, isGeneratingPredictions, isAppending]);

  useEffect(() => {
    mutateMeeting();
  }, [autoGeneratedFields]);

  useEffect(() => {
    if (isLoading || associatedCRMRecords.length > 0) return;
    setAssociatedCRMRecordModalOpen(true);
  }, [isLoading]);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const errorMessages: { objectLabel: string; message: string }[] = [];

    for (const fieldConfigurationGroup of fieldConfigurationGroups) {
      const syncedCRMObject = fieldConfigurationGroup.synced_crm_object;

      if (!syncedCRMObject) continue;

      const associatedCRMRecord = findAssociatedCRMRecord(
        associatedCRMRecords,
        syncedCRMObject
      );

      if (!associatedCRMRecord) continue;

      const crmRecord: CRMRecord = values[associatedCRMRecord.crm_record_id];

      if (!crmRecord) continue;

      const updatedCRMRecord: CRMRecord = {
        crm: syncedCRMObject.crm_provider_name,
        crm_object_type: syncedCRMObject.crm_object_type,
        crm_record_name: associatedCRMRecord.crm_record_name,
        crm_record_id: associatedCRMRecord.crm_record_id,
        crm_record_fields: crmRecord.crm_record_fields.map(
          (crmField: CRMField) => {
            const crmObjectFormValues = values[fieldConfigurationGroup.uuid];
            const value = crmObjectFormValues?.[crmField.crm_field_name];

            return {
              ...crmField,
              crm_field_value: value,
            };
          }
        ),
      };

      try {
        await fetchUpdateCRMRecord({
          objectType: syncedCRMObject.crm_object_type,
          recordNameProperty: syncedCRMObject.searchable_crm_field_name,
          recordId: associatedCRMRecord.crm_record_id,
          crmRecord: updatedCRMRecord,
          associatedCRMRecordUuid: associatedCRMRecord.uuid,
          meetingUuid: meetingId,
        });
      } catch (error) {
        errorMessages.push({
          objectLabel: syncedCRMObject.crm_object_label,
          message: error.response.data.message,
        });
      }
    }

    if (errorMessages.length === 0) {
      if (user.workflows_enabled) {
        try {
          await triggerWorkflow(WorkflowTrigger.MeetingSaved, {
            meeting_uuid: meetingId,
          });
        } catch (e) {
          message.error(
            `Error triggering workflows: ${
              e.response ? e.response.data.message : e.message
            }`
          );
        }
      }

      Modal.success({
        width: 500,
        title: `${getFriendlyIntegrationLabel(
          user.organization.crm_provider_name
        )} Synced Successfully! 🎉`,
        content: "Would you like to update another meeting?",
        okText: "Update Another Meeting",
        cancelText: "Keep Editing",
        onOk: () => navigate("/dashboard/meetings"),
        okCancel: true,
      });
    } else {
      Modal.warn({
        width: 600,
        title: `An error occurred while syncing to ${getFriendlyIntegrationLabel(
          user.organization.crm_provider_name
        )}`,
        content: (
          <Row gutter={[12, 14]}>
            <Col span={24}>
              <Typography.Text>
                Please review and/or resolve the following errors:
              </Typography.Text>
            </Col>

            {errorMessages.map(({ objectLabel, message }, i) => (
              <Col key={i} span={24}>
                <Alert
                  type="error"
                  message={
                    <>
                      <b>Error updating {objectLabel}:</b> {message}
                    </>
                  }
                />
              </Col>
            ))}
          </Row>
        ),
        okText: "Close",
      });
    }

    mutateMeeting();
    setFormSubmittedAt(new Date());
    setIsSubmitting(false);
  };

  const validationMessages = {
    required: "${label} is required!",
    number: {
      len: "${label} must be equal to ${len}",
      min: "${label} must be minimum ${min}",
      max: "${label} must be maximum ${max}",
      range: "${label} must be between ${min}-${max}",
    },
  };

  return (
    <Spin
      spinning={isLoading}
      tip={
        <>
          {isGeneratingPredictions && (
            <Space direction="vertical" style={{ marginTop: 20 }}>
              <Typography.Text>
                Hang tight, Swyft is automatically generating meeting details...
              </Typography.Text>

              <Typography.Text>
                {`We'll notify you when they're complete!`}
              </Typography.Text>
            </Space>
          )}
          {isAppending && (
            <Space direction="vertical" style={{ marginTop: 20 }}>
              <Typography.Text>
                Hang tight, Swyft is intelligently combining insights with your
                CRM data...
              </Typography.Text>

              <Typography.Text>
                {`This might take up to a minute!`}
              </Typography.Text>
            </Space>
          )}
        </>
      }
    >
      <AssociatedCRMRecordModal
        open={associatedCRMRecordModalOpen}
        meeting={meeting}
        autoGeneratedFields={autoGeneratedFields}
        syncedCRMObjects={fieldConfigurationGroups
          .filter(({ synced_crm_object }) => synced_crm_object)
          .map(({ synced_crm_object }) => synced_crm_object)}
        associatedCRMRecords={associatedCRMRecords}
        predictedAssociatedCRMRecords={predictedAssociatedCRMRecords}
        onSave={() => {
          mutateAssociatedCRMRecords();
          mutatePredictedAssociatedCRMRecords();
          mutateMeeting();
        }}
        onClose={() => setAssociatedCRMRecordModalOpen(false)}
      />

      <Space direction="vertical" style={{ display: "flex" }} size="large">
        {meetingIntegration && (
          <MeetingProviderAlert meetingIntegration={meetingIntegration} />
        )}

        <Row align="top" justify="space-between" style={{ marginBottom: 15 }}>
          <Col span={12}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Breadcrumb
                  items={[
                    {
                      title: (
                        <a onClick={() => navigate(meetingsPath())}>
                          My Meetings
                        </a>
                      ),
                    },
                    { title: meeting?.topic },
                  ]}
                />
              </Col>

              <Col span={24}>
                <Typography.Title
                  level={3}
                  style={{ margin: 0 }}
                  editable={{
                    onChange: async (value) => {
                      await fetchUpdateMeeting(meetingId, {
                        user_modified_topic: value,
                      });
                      mutateMeeting();
                    },
                  }}
                >
                  {meeting?.topic}
                </Typography.Title>
              </Col>

              <Col span={24}>
                <Space size="small">
                  {meeting && <DateTag dateTime={meeting.start_time} />}
                  {meeting && <MeetingStatus meeting={meeting} />}

                  <Button
                    icon={
                      <CRMIcon crm={user?.organization?.crm_provider_name} />
                    }
                    size="small"
                    onClick={() => setAssociatedCRMRecordModalOpen(true)}
                  >
                    {associatedCRMRecords.length} Associated Records
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>

          <Col>
            {regenerationAllowed && (
              <Button
                type="primary"
                disabled={
                  !meetingIntegration?.connected && !meeting?.is_manual_upload
                }
                onClick={async () => {
                  await fetchRegeneratePredictions(meeting?.uuid);
                  mutateMeeting();
                  mutateAssociatedCRMRecords();
                  mutatePredictedAssociatedCRMRecords();
                  mutateAutoGeneratedFields();
                }}
              >
                Regenerate Predictions
              </Button>
            )}
          </Col>
        </Row>

        <Form
          form={form}
          name="MeetingForm"
          initialValues={{ remember: true }}
          onFinish={(values) => {
            const acceptedFields = autoGeneratedFields.filter(
              ({ accepted }) => accepted === true
            );

            if (acceptedFields.length === 0) {
              Modal.confirm({
                title: "You haven't approved any fields",
                content:
                  "Fields that haven't been approved or edited won't sync to your CRM. Are you sure you want to continue?",
                okText: `Yes, sync to ${getFriendlyIntegrationLabel(
                  user?.organization?.crm_provider_name
                )}`,
                cancelText: "No, keep editing",
                onOk: () => handleSubmit(values),
                width: "30rem",
              });
            } else {
              handleSubmit(values);
            }
          }}
          layout="vertical"
          scrollToFirstError
          validateMessages={validationMessages}
          style={{ marginBottom: "6rem" }}
        >
          <>
            {fieldConfigurationGroups &&
              fieldConfigurationGroupLayouts.map((groupLayout) => {
                const fieldConfigurationGroup = fieldConfigurationGroups.find(
                  (group) =>
                    group.uuid === groupLayout.field_configuration_group.uuid
                );

                if (groupLayout.field_configuration_layouts.length === 0)
                  return null;
                if (!meeting || isLoading) return <Skeleton />;

                return (
                  <Col span={24} key={fieldConfigurationGroup.uuid}>
                    <MeetingFormFields
                      form={form}
                      formSubmittedAt={formSubmittedAt}
                      meeting={meeting}
                      mutateMeeting={mutateMeeting}
                      autoGeneratedFields={autoGeneratedFields}
                      fieldConfigurationGroup={fieldConfigurationGroup}
                      layout={groupLayout}
                    />

                    <Divider />
                  </Col>
                );
              })}
          </>

          {!isLoading && (
            <Affix offsetBottom={15} key="affix">
              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    boxShadow: "8px 5px 5px rgba(140, 140, 140, 0.25)",
                  }}
                  icon={<CRMIcon crm={user?.organization?.crm_provider_name} />}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  disabled={isLoading || associatedCRMRecords.length === 0}
                >
                  {`Sync to ${getFriendlyIntegrationLabel(
                    user?.organization?.crm_provider_name
                  )}`}
                </Button>
              </Form.Item>
            </Affix>
          )}
        </Form>
      </Space>
    </Spin>
  );
}
