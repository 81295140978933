import useSWR, { KeyedMutator } from "swr";
import {
  Meeting,
  Product,
  Pagination,
  Organization,
  User,
  FieldConfiguration,
  AutoGeneratedField,
  Workflow,
  Team,
  CrmRole,
  CRMRecord,
  CRMProvider,
  CRMObject,
  CRMField,
  AssociatedCRMRecord,
  FieldConfigurationGroup,
  SlackChannel,
  SlackUser,
  WorkflowInstance,
  WorkflowState,
  IntegrationInfo,
  FieldConfigurationGroupLayout,
  WorkflowTransition,
} from "./types";
import { get, forEach } from "lodash";
import { Integration, WorkflowStatus } from "./enums";

export interface swrError extends Error {
  status?: number;
  info?: string;
}

const fetcher = async (url) => {
  const res = await fetch(url);

  if (res.ok) return res.json();

  const error: swrError = new Error(
    "An error occurred while fetching the data."
  );

  error.info = await res.json();
  error.status = res.status;

  throw error;
};

interface RequestStatus {
  error: boolean;
  isLoading: boolean;
  isValidating?: boolean;
  mutate: KeyedMutator<unknown>;
}

export const useMeetings = ({
  page = 0,
  pageSize = 10,
  shouldPaginate = true,
}: {
  page?: number;
  pageSize?: number;
  shouldPaginate?: boolean;
}): {
  meetings: Meeting[];
  meta: Pagination;
} & RequestStatus => {
  const url = shouldPaginate
    ? `/api/meetings?page=${page}&page_size=${pageSize}`
    : "/api/meetings";

  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    refreshInterval: (data) => (data?.meta?.is_syncing ? 3000 : 30000),
  });

  return {
    meetings: get(data, "meetings", []),
    meta: get(data, "meta", {}),
    error,
    isLoading,
    mutate,
  };
};

export const useOrganizationMeetings = ({
  page = 0,
  pageSize = 10,
  shouldPaginate = true,
}: {
  page?: number;
  pageSize?: number;
  shouldPaginate?: boolean;
}): {
  organizationMeetings: Meeting[];
  meta: Pagination;
} & RequestStatus => {
  const url = shouldPaginate
    ? `/api/organization_meetings?page_size=${pageSize}&page=${page}`
    : `/api/organization_meetings`;

  const { data, error, isLoading, mutate } = useSWR(url, fetcher);

  return {
    organizationMeetings: get(data, "organization_meetings", []),
    meta: get(data, "meta", {}),
    error,
    isLoading,
    mutate,
  };
};

export const useMeeting = (
  meetingUuid?: string
): {
  meeting: Meeting | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Meeting>(
    meetingUuid ? `/api/meetings/${meetingUuid}` : null,
    fetcher,
    {
      refreshInterval: (meeting) => {
        if (!meeting?.predictions_completed_at) {
          return 5000;
        }

        if (meeting?.append_requested_at && !meeting?.append_completed_at) {
          return 2000;
        }

        return 0;
      },
    }
  );

  return { meeting: data, error, isLoading, mutate };
};

export const useProducts = (
  page: number,
  pageSize: number
): {
  products: Product[];
  meta: Pagination | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<{
    products: Product[];
    meta: Pagination;
  }>(`/api/products?page_size=${pageSize}&page=${page}`, fetcher);

  return {
    products: get(data, "products", []),
    meta: data ? get(data, "meta") : undefined,
    error,
    isLoading,
    mutate,
  };
};

export const useProduct = (
  uuid: string
): {
  product: Product;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    uuid ? `/api/products/${uuid}` : null,
    fetcher
  );

  return { product: data || {}, error, isLoading, mutate };
};

export const useOrganizations = (): {
  organizations: Organization[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Organization[]>(
    `/api/organizations`,
    fetcher
  );

  return {
    organizations: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useOrganization = (
  organizationUuid: string
): {
  organization: Organization;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/organizations/${organizationUuid}`,
    fetcher
  );

  return { organization: data || {}, error, isLoading, mutate };
};

export const useUser = (): { user: User } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(`/api/users/me`, fetcher);
  return { user: data || {}, error, isLoading, mutate };
};

export const useMyIntegrations = (
  shouldFetch = true
): {
  meetingIntegrations: IntegrationInfo[];
  crmIntegration: IntegrationInfo;
  notificationIntegrations: IntegrationInfo[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? `/api/integrations` : null,
    fetcher
  );

  return {
    meetingIntegrations: get(data, "meeting_integrations", []),
    crmIntegration: get(data, "crm_integration", null),
    notificationIntegrations: get(data, "notification_integrations", []),
    error,
    isLoading,
    mutate,
  };
};

export const useOrganizationUsersIntegrations = (
  shouldFetch = true
): {
  userIntegrations: {
    uuid: string;
    meeting_integrations: IntegrationInfo[];
    crm_integration: IntegrationInfo;
    notification_integrations: IntegrationInfo[];
  }[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? `/api/integrations/organization_users` : null,
    fetcher
  );

  return { userIntegrations: data || [], error, isLoading, mutate };
};

export const useAutoGeneratedFields = (
  meetingUuid?: string,
  requestPredictions: boolean = true
): {
  autoGeneratedFields: AutoGeneratedField[];
} & RequestStatus => {
  const { data, error, isLoading, mutate, isValidating } = useSWR<
    AutoGeneratedField[]
  >(
    meetingUuid
      ? `/api/meetings/${meetingUuid}/auto_generated_fields?request_predictions=${requestPredictions}`
      : null,
    fetcher
  );

  return {
    autoGeneratedFields: data || [],
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useAssociatedCRMRecords = (
  meetingUuid?: string,
  isPredicted?: boolean
): {
  associatedCRMRecords: AssociatedCRMRecord[];
} & RequestStatus => {
  let endpoint = `/api/meetings/${meetingUuid}/associated_crm_records`;
  if (isPredicted) endpoint += `?is_predicted=${isPredicted}`;

  const { data, error, isLoading, mutate, isValidating } = useSWR<
    AssociatedCRMRecord[]
  >(meetingUuid ? endpoint : null, fetcher);

  return {
    associatedCRMRecords: data || [],
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useOrganizationFieldConfiguration = (
  fieldConfigurationUuid: string
): {
  fieldConfiguration: FieldConfiguration | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate, isValidating } =
    useSWR<FieldConfiguration>(
      `/api/field_configurations/${fieldConfigurationUuid}`,
      fetcher
    );

  return {
    fieldConfiguration: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useOrganizationAssemblyMeetings = (
  page: number = 0,
  pageSize: number = 10
): {
  meetings: Meeting[];
  meeting_summaries: { [meeting_uuid: string]: string };
  meta: Pagination;
} & RequestStatus => {
  const { data, error, isLoading, mutate, isValidating } = useSWR(
    `/api/organizations/assembly_meetings?page_size=${pageSize}&page=${page}`,
    fetcher,
    {
      refreshInterval: (data) =>
        data?.pending_transcript_count > 0 ? 2000 : 0,
    }
  );

  return {
    meetings: get(data, "organization_meetings", []),
    meeting_summaries: get(data, "meeting_summaries", {}),
    meta: get(data, "meta", {}),
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useCRMObjects = (
  crm: CRMProvider
): {
  objects: CRMObject[];
} & RequestStatus => {
  const endpoint = crm
    ? `/api/crm/objects?crm_provider_name=${crm}`
    : "/api/crm/objects";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);
  return { objects: data || [], error, isLoading, mutate };
};

export const useCRMObjectFields = (
  crm: CRMProvider,
  objectType: CRMObject | string,
  includeAllFields: boolean = false
): {
  objectFields: CRMField[];
} & RequestStatus => {
  const endpoint =
    crm && objectType
      ? `/api/crm/object_fields?crm_provider_name=${crm}&object_type=${objectType}&include_all_fields=${includeAllFields}`
      : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { objectFields: data || [], error, isLoading, mutate };
};

export const useFindCRMRecordById = (
  object_type: string,
  record_name_property: string,
  id: string
): {
  record: CRMRecord;
} & RequestStatus => {
  let endpoint = `/api/crm/find_record?object_type=${object_type}`;

  if (record_name_property) {
    endpoint += `&record_name_property=${record_name_property}`;
  }

  if (id) endpoint += `&id=${id}`;

  const { data, error, mutate, isLoading } = useSWR(
    object_type ? endpoint : null,
    fetcher
  );
  return { record: data, error, mutate, isLoading };
};

export const buildQueryParams = (params) => {
  let query = ``;
  forEach(params, (value, key) => {
    if (!query.length) {
      query += `?${key}=${value}`;
    } else {
      query += `&${key}=${value}`;
    }
  });

  return query;
};

export const useWorkflows = (
  team: Team
): { workflows: Workflow[] } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    () => `/api/workflows?team_uuid=${team.uuid}`,
    fetcher
  );

  return { workflows: data, error, isLoading, mutate };
};

export const useWorkflow = (
  workflowUuid: string
): { workflow: Workflow } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/workflows/${workflowUuid}`,
    fetcher
  );

  return { workflow: data, error, isLoading, mutate };
};

export const useWorkflowInstances = (
  workflowUuid: string
): { workflowInstances: WorkflowInstance[] } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/workflows/${workflowUuid}/workflow_instances`,
    fetcher,
    { refreshInterval: 2500 }
  );

  return { workflowInstances: data || [], error, isLoading, mutate };
};

export const useWorkflowInstance = (
  workflowUuid: string,
  workflowInstanceUuid: string
): { workflowInstance: WorkflowInstance } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/workflows/${workflowUuid}/workflow_instances/${workflowInstanceUuid}`,
    fetcher,
    {
      refreshInterval: (data) =>
        data?.status === WorkflowStatus.InProgress ? 2000 : 0,
    }
  );

  return { workflowInstance: data, error, isLoading, mutate };
};

export const useWorkflowState = (
  workflowUuid: string,
  workflowStateUuid: string
): { workflowState: WorkflowState } & RequestStatus => {
  const endpoint =
    workflowUuid && workflowStateUuid
      ? `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}`
      : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { workflowState: data, error, isLoading, mutate };
};

export const useWorkflowStates = (
  workflowUuid: string
): { workflowStates: WorkflowState[] } & RequestStatus => {
  const endpoint = workflowUuid
    ? `/api/workflows/${workflowUuid}/workflow_states`
    : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { workflowStates: data || [], error, isLoading, mutate };
};

export const useWorkflowTransition = (
  workflowTransitionUuid: string
): { workflowTransition: WorkflowTransition } & RequestStatus => {
  const endpoint = workflowTransitionUuid
    ? `/api/workflow_transitions/${workflowTransitionUuid}`
    : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { workflowTransition: data, error, isLoading, mutate };
};

export const useTeam = (
  teamUuid: string
): {
  team: Team | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Team>(
    () => (teamUuid ? `/api/teams/${teamUuid}` : null),
    fetcher
  );

  return {
    team: data,
    error,
    isLoading,
    mutate,
  };
};

export const useTeams = (): {
  teams: Team[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Team[]>(
    () => `/api/teams`,
    fetcher
  );

  return {
    teams: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useOrganizationCrmRoles = (
  organization: Organization
): {
  crmRoles: CrmRole[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<CrmRole[]>(
    () => `/api/organizations/${organization.uuid}/crm_roles`,
    fetcher
  );

  return {
    crmRoles: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useTeamFieldConfigurationGroups = (
  teamUuid: string
): {
  fieldConfigurationGroups: FieldConfigurationGroup[];
} & RequestStatus => {
  const url = teamUuid
    ? `/api/teams/${teamUuid}/field_configuration_groups`
    : null;

  const { data, error, isLoading, mutate } = useSWR<FieldConfigurationGroup[]>(
    () => url,
    fetcher
  );

  return {
    fieldConfigurationGroups: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useSlackChannels = (): {
  channels: SlackChannel[];
} & RequestStatus => {
  const endpoint = "/api/slack/channels";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });
  return { channels: data || [], error, isLoading, mutate };
};

export const useSlackUsers = (): {
  slackUsers: SlackUser[];
} & RequestStatus => {
  const endpoint = "/api/slack/users";

  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { slackUsers: data || [], error, isLoading, mutate };
};

export const useOrganizationUsers = (
  organization?: Organization
): {
  users: User[];
} & RequestStatus => {
  let query = "";
  if (organization) {
    query = buildQueryParams({ id: organization.uuid });
  }
  const { data, error, isLoading, mutate } = useSWR<User[]>(
    () => `/api/organizations/users` + query,
    fetcher
  );

  return {
    users: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useFieldConfigurationGroupLayouts = (team?: {
  uuid: string;
}): {
  fieldConfigurationGroupLayouts: FieldConfigurationGroupLayout[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<
    FieldConfigurationGroupLayout[]
  >(() => `/api/teams/${team.uuid}/field_configuration_group_layouts`, fetcher);

  return {
    fieldConfigurationGroupLayouts: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useIntegrationUsers = (
  integration: Integration
): {
  response: {
    merged_users: {
      user_uuid: string;
      user_email: string;
      integration_user_id: string;
      integration_user_name: string;
      integration_user_email: string;
    }[];
    integration_users: {
      id: string;
      name: string;
      email: string;
    }[];
  };
} & RequestStatus => {
  const endpoint = `/api/integrations/connected_users?integration_provider_name=${integration}`;

  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    response: data || { merged_users: [], integration_users: [] },
    error,
    isLoading,
    mutate,
  };
};

export const useMicrosoftTeams = (): {
  teams: { id: string; name: string }[];
} & RequestStatus => {
  const endpoint = "/api/microsoft/teams";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { teams: data || [], error, isLoading, mutate };
};

export const useMicrosoftChannels = (
  teamId: string
): {
  channels: { id: string; name: string }[];
} & RequestStatus => {
  const endpoint = teamId ? `/api/microsoft/channels?team_id=${teamId}` : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { channels: data || [], error, isLoading, mutate };
};
